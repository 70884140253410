import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { API_URL } from '../config';
import { Phone, Mail, Calendar } from 'lucide-react';

const ChatHistory = () => {
  const [conversations, setConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const [hideAnonymous, setHideAnonymous] = useState(false);

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    try {
      const response = await axios.get(`${API_URL}/chat_history`);
      setConversations(response.data["response"]);

      // Automatically select the first conversation if available
      if (response.data.length > 0) {
        setSelectedConversation(response.data[0]);
      }
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  const filterConversations = useCallback(() => {
    if (hideAnonymous) {
      setFilteredConversations(conversations.filter(conv => !conv.userName.startsWith("Anonymous_")));
    } else {
      setFilteredConversations(conversations);
    }
  }, [conversations, hideAnonymous]);

  useEffect(() => {
    filterConversations();
  }, [conversations, hideAnonymous, filterConversations]);

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => <p className="mb-2" {...props} />,
          h1: ({ node, ...props }) => <h1 className="text-2xl font-bold mb-3" {...props}>{props.children || 'Heading 1'}</h1>,
          h2: ({ node, ...props }) => <h2 className="text-xl font-bold mb-2" {...props}>{props.children || 'Heading 2'}</h2>,
          h3: ({ node, ...props }) => <h3 className="text-lg font-bold mb-2" {...props}>{props.children || 'Heading 3'}</h3>,
          ul: ({ node, ...props }) => <ul className="list-disc pl-5 mb-2" {...props} />,
          ol: ({ node, ...props }) => <ol className="list-decimal pl-5 mb-2" {...props} />,
          li: ({ node, ...props }) => <li className="mb-1" {...props} />,
          a: ({ node, ...props }) => <a className="text-blue-600 underline" target="_blank" rel="noopener noreferrer" {...props}>{props.children || 'Link'}</a>,
          code: ({ node, inline, ...props }) => (
            inline ? <code className="bg-gray-100 px-1 rounded" {...props} /> : <pre className="bg-gray-100 p-2 rounded overflow-x-auto" {...props} />
          ),
        }}
      >
        {content}
      </ReactMarkdown>
    );
  };

  const renderDate = (content) => {
    return (
      <>{content.timestamp}</>
    );
  };

  const renderConversation = (content) => {
    let messages;
    try {
      messages = content;
    } catch (error) {
      console.error('Error parsing conversation content:', error);
      return <div>Error displaying conversation</div>;
    }

    return messages.map((message, index) => (
      <div key={index} className={`mb-4 ${message.role === 'user' ? 'pl-6 pr-4' : 'pr-6 pl-4'}`}>
        <div className={`p-4 rounded-3xl ${message.role === 'user' ? 'bg-[#e0e7f7] rounded-tl-none' : 'bg-[#f6f6f6] rounded-tr-none'}`}>
          <div className="flex justify-between items-center mb-4">
            <div className="text-[#242424] text-[18px] font-semibold font-['Raleway']">
              {message.role === 'user' ? selectedConversation.userName : 'Credence'}
            </div>
            <div className="opacity-60 text-[#242424] text-[15px] font-normal font-['Raleway']">
              {renderDate(message)}
            </div>
          </div>
          <div className="text-[#242424] text-[15px] font-medium font-['Raleway'] leading-[25.20px]">
            {renderMessage(message.content)}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="flex flex-col h-full bg-gray-100 p-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-[#242424] text-2xl font-semibold font-['Raleway']">Chatbot History</h1>
        <label className="inline-flex items-center cursor-pointer">
          <input
            type="checkbox"
            className="sr-only peer"
            checked={hideAnonymous}
            onChange={() => setHideAnonymous(!hideAnonymous)}
          />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
          <span className="ms-3 text-sm font-medium font-semibold text-[#242424]">Hide Anonymous Users</span>
        </label>
      </div>
      <div className="flex flex-1 flex-col md:flex-row">
        <div className="w-full md:w-1/3 lg:w-1/4 bg-white rounded-2xl overflow-y-auto mb-4 md:mb-0 md:mr-4">
          {filteredConversations.map((conv) => (
            <div
              key={conv.id}
              className={`cursor-pointer p-5 ${selectedConversation && selectedConversation.id === conv.id
                ? 'bg-[#e0e7f7] border-r-4 border-[#175EFF]'
                : 'hover:bg-gray-100'
                }`}
              onClick={() => setSelectedConversation(conv)}
            >
              <div className="text-[#242424] text-lg font-semibold font-['Raleway']">{conv.userName}</div>
            </div>
          ))}
        </div>
        {selectedConversation && (
          <>
            <div className="flex-1 bg-white rounded-2xl p-6 overflow-y-auto mb-4 md:mb-0 md:mr-4">
              {renderConversation(selectedConversation.content)}
            </div>
            <div className="w-full md:w-1/3 lg:w-1/4 p-6 bg-white rounded-2xl flex flex-col gap-4">
              <div className="w-full flex flex-col md:flex-row justify-between items-start md:items-center">
                <div className="text-[#242424] text-lg md:text-xl font-semibold font-['Raleway'] mb-2 md:mb-0">
                  {selectedConversation.userName}
                </div>
              </div>

              {/* Phone number */}
              <div className="flex flex-col w-full">
                <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway']">Phone number:</div>
                <div className="w-full p-3 bg-[#f6f6f6] rounded-[14px] flex flex-col md:flex-row items-start md:items-center gap-3.5">
                  <Phone size={18} className="text-gray-500" />
                  <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway']">
                    {selectedConversation.userPhone || 'Not provided'}
                  </div>
                </div>
              </div>

              {/* Email address */}
              <div className="flex flex-col w-full">
                <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway']">Email address:</div>
                <div className="w-full p-3 bg-[#f6f6f6] rounded-[14px] flex flex-col md:flex-row items-start md:items-center gap-3.5">
                  <Mail size={18} className="text-gray-500 flex-shrink-0" />
                  <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway'] break-all" style={{ maxWidth: '100%' }}>
                    {selectedConversation.userEmail || 'Not provided'}
                  </div>
                </div>
              </div>


              {/* Conversation date */}
              <div className="flex flex-col w-full">
                <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway']">Conversation date:</div>
                <div className="w-full p-3 bg-[#f6f6f6] rounded-[14px] flex flex-col md:flex-row items-start md:items-center gap-3.5">
                  <Calendar size={18} className="text-gray-500" />
                  <div className="text-[#242424] text-sm md:text-base font-medium font-['Raleway']">
                    {selectedConversation.date || 'Not available'}
                  </div>
                </div>
              </div>
            </div>

          </>
        )}
      </div>
    </div>
  );
};

export default ChatHistory;
