// eslint-disable-next-line no-unused-vars
import React, { act, useState } from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import KnowledgeBase from './KnowledgeBase';
import Campaigns from './Campaigns';
import ChatHistory from './ChatHistory';
import SMSCampaigns from './SMSCampaigns';

const Dashboard = () => {
  const [activeItem, setActiveItem] = useState('Knowledge Base');
  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Header />

      <div className="flex flex-1 overflow-hidden">
        {/* Sidebar for larger screens */}
        <div className="hidden md:flex md:w-1/4 lg:w-[292px]">
          <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
        </div>

        {/* Mobile Sidebar Toggle Button */}
        <div className="md:hidden p-4">
          <button
            className="bg-blue-500 text-white p-2 rounded-md"
            onClick={() => setShowSidebar(!showSidebar)}
          >
            Menu
          </button>
        </div>

        {/* Sidebar for mobile view */}
        {showSidebar && (
          <div className="absolute top-0 left-0 w-3/4 h-screen bg-white shadow-lg z-10 md:hidden">
            <Sidebar activeItem={activeItem} setActiveItem={setActiveItem} />
            <button
              className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full"
              onClick={() => setShowSidebar(false)}
            >
              Close
            </button>
          </div>
        )}

        {/* Main content area */}
        <main className="flex-1 overflow-auto bg-gray-100 p-4">
          {activeItem === 'Knowledge Base' && <KnowledgeBase />}
          {activeItem === 'Campaigns' && <Campaigns />}
          {activeItem === 'Chatbot' && <ChatHistory />}
          {activeItem === 'SMS Campaigns' && <SMSCampaigns />}  
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
