import React, { useState, useEffect } from "react";
import NewCampaigns from "./NewCampaigns";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FiArrowLeft } from "react-icons/fi";
import Tooltip from "@mui/material/Tooltip";
import {
  copyCampaign,
  fetchSMSCampaigns,
  updateCampaignStatusDuplicate,
} from "../api";
import { API_URL } from "../config";
const SMSCampaigns = () => {
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const [showConfirmDuplicate, setShowConfirmDuplicate] = useState(false);
  const [duplicateCampaignId, setDuplicateCampaignId] = useState(0);
 
  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [campaignToDelete, setCampaignToDelete] = useState(null);
  const [campaignToEdit, setCampaignToEdit] = useState(null);
  const [campaignStatus, setCampaignStatus] = useState(null);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const fetchCampaigns = async () => {
      try {
        const response = await fetchSMSCampaigns();
        setCampaigns(response.data);
      } catch (error) {
        toast.error("Error fetching campaigns:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchCampaigns();
  }, [refresh]);

  const handleOpenDeletePopup = (id) => {
    setCampaignToDelete(id);
    setShowDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setShowDeletePopup(false);
    setCampaignToDelete(null);
  };

  const handleOpenDuplicatePopup = (id) => {
    setDuplicateCampaignId(id);
    setShowConfirmDuplicate(true);
  };

  const handleCloseDuplicatePopup = () => {
    setDuplicateCampaignId(0);
    setShowConfirmDuplicate(false);
  };



  const handleCreateCampaign = () => {
    setIsCreatingCampaign(true);
    setCampaignToEdit(null);
  };

  const handleEdit = (id) => {
    const selectedCampaign = campaigns.find((campaign) => campaign.id === id);
    setCampaignToEdit({
      ...selectedCampaign,
      status: selectedCampaign.status,
    });
    setCampaignStatus({
      ...selectedCampaign.status,
    });
    setIsCreatingCampaign(true);
  };

  const handleDuplicate = async () => {
    try {
      const response = await copyCampaign(duplicateCampaignId);
      if (response.status === 201) {
        const newCampaignId = response.data.campaign_id;
        await updateCampaignStatusDuplicate(newCampaignId, "DRAFT");
        setRefresh((prev) => !prev);
        toast.success("Duplicate Campaign Created");
        handleCloseDuplicatePopup(false)
      }
      
    } catch (error) {
      toast.error("Error duplicating campaign:", error);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`${API_URL}/campaign/${campaignToDelete}`);
      setCampaigns(
        campaigns.filter((campaign) => campaign.id !== campaignToDelete)
      );
      toast.success("Campaign Deleted");
    } catch (error) {
      toast.error("Error deleting campaign:", error);
    } finally {
      setShowDeletePopup(false);
    }
  };

  const handleView = (id) => {
    const selectedCampaign = campaigns.find((campaign) => campaign.id === id);
    if (selectedCampaign) {
      setCampaignToEdit({
        ...selectedCampaign,
        status: selectedCampaign.status,
      });
      setCampaignStatus({
        ...selectedCampaign.status,
      });
      setIsCreatingCampaign(true);
    } else {
      toast.error("Campaign not found");
    }
  };
  if (loading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="flex flex-col h-full">
      <style>
        {
          "\
        thead th {\
          border-top: 1px solid;\
          border-bottom: 1px solid;\
          border-color: #EBEBEB;\
        }\
         thead th.firstColumn {\
          border-left: 1px solid;\
          border-color: #EBEBEB;\
        }\
         thead th.lastColumn {\
          border-right: 1px solid;\
          border-color: #EBEBEB;\
        }\
      "
        }
      </style>
      {!isCreatingCampaign ? (
        <>
          <h1 className="text-[#242424] text-2xl font-semibold font-['Raleway'] mb-4">
            SMS Campaigns
          </h1>
          {campaigns.length === 0 ? (
            <div className="flex-grow p-8 bg-white rounded-2xl flex flex-col justify-center items-center">
              <img
                className="w-14 h-12 mb-8"
                src="/images/Scanner.jpeg"
                alt="No Campaigns"
              />
              <p className="text-[#242424] text-lg mb-8">
                There's nothing to see. There are no campaigns yet!
              </p>
              <button
                onClick={handleCreateCampaign}
                className="h-16 px-12 bg-[#175eff] rounded-2xl flex justify-center items-center gap-3"
              >
                <div className="w-6 h-6 p-0.5 flex justify-center items-center">
                  <img className="w-5 h-5" src="/images/Plus.png" alt="Add" />
                </div>
                <div className="text-white text-lg font-medium font-['Raleway']">
                  Create your first SMS campaign
                </div>
              </button>
            </div>
          ) : (
            <div className="flex-grow bg-white rounded-2xl relative">
              <div
                className="overflow-x-auto m-5"
                style={{
                  maxHeight: "560px",
                }}
              >
                <table
                  className="min-w-full table-auto"
                  style={{ borderRadius: "10px" }}
                >
                  <thead
                    style={{
                      backgroundColor: "#F6F6F6",
                      borderSpacing: " 10px",
                    }}
                    className="rounded-2xl"
                  >
                    <tr>
                      <th className="firstColumn  text-center p-3">Date</th>
                      <th className="text-center p-3">Campaign Name</th>
                      <th className="text-center p-3">Message</th>
                      <th className="text-center p-3">Status</th>
                      <th className="text-center p-3">Recipients</th>
                      <th className="text-center p-3">Delivered</th>
                      <th className="text-center p-3">Undelivered</th>
                      <th className="lastColumn text-center p-3">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.length > 0 ? (
                      campaigns.map((campaign, index) => (
                        <tr
                          key={campaign.id}
                          className={`${
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }`}
                        >
                          <td className="text-center p-2">
                            {new Date(campaign.created_at).toLocaleDateString()}
                          </td>
                          <td className="text-center p-2">
                            <TruncatedText
                              text={campaign.name}
                              maxLength={10}
                            />
                          </td>
                          <td
                            className="text-center p-2"
                            style={{ minWidth: "160px" }}
                          >
                            <TruncatedText
                              text={campaign.message}
                              maxLength={10}
                            />
                          </td>
                          <td className="text-center p-2">{campaign.status}</td>
                          <td className="text-center p-2">
                            {campaign.total_recipients || "0"}
                          </td>
                          <td className="text-center p-4">
                            {campaign.recipient_statuses.delivered || "0"}
                          </td>
                          <td className="text-center p-2">
                            {campaign.recipient_statuses.undelivered || "0"}
                          </td>
                          <td
                            className="text-center flex gap-2 justify-center p-2"
                            style={{ minWidth: "160px" }}
                          >
                            <span
                              className="text-blue-400 cursor-pointer"
                              onClick={() =>
                                campaign.status === "SENT"
                                  ? handleView(campaign.id)
                                  : handleEdit(campaign.id)
                              }
                            >
                              {campaign.status === "SENT" ? "View" : "Edit"}
                            </span>
                            {campaign.status !== "DRAFT" && (
                              <button
                                className={`w-4 h-3 cursor-pointer ${
                                  campaign.status === "SENT"
                                    ? "text-green-500"
                                    : "text-gray-400 cursor-not-allowed"
                                }`}
                                onClick={() => {
                                  if (campaign.status === "SENT") {
                                    handleOpenDuplicatePopup(campaign.id)
                                  }
                                }}
                              >
                                Duplicate
                              </button>
                            )}
                            <Tooltip title="Delete" placement="top" arrow>
                              {campaign.status === "DRAFT" && (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  className="w-5 h-4 mt-1 ml-4 text-red-500 cursor-pointer"
                                  onClick={() =>
                                    handleOpenDeletePopup(campaign.id)
                                  }
                                />
                              )}
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center p-3">
                          No campaigns found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="fixed bottom-9 right-4 md:right-16">
                <button
                  onClick={handleCreateCampaign}
                  className="h-10 md:h-12 px-4 md:px-6 bg-[#175eff] rounded-xl md:rounded-2xl text-white flex items-center"
                >
                  <img
                    className="w-3 h-3 md:w-4 md:h-4 mr-2"
                    src="/images/Plus.png"
                    alt="Add"
                  />
                  <span className="text-sm md:text-base">
                    Create New SMS Campaign
                  </span>
                </button>
              </div>
            </div>
          )}
        </>
      ) : (
        <NewCampaigns
          campaignData={campaignToEdit}
          campaignData1={campaignStatus}
        />
      )}
      {showConfirmDuplicate && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">
                  Duplicate Campaign
                </h2>
                <p className="my-4">
                  Are you about to Duplicate the campaign? Please confirm before proceeding.
                </p>
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() => setShowConfirmDuplicate(false)}
                    className=" px-4 py-2 rounded-lg flex items-center"
                  >
                    <FiArrowLeft className="w-6 h-6 mr-2" />
                    No, go back
                  </button>
                  <button
                    onClick={handleDuplicate}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg"
                  >
                    Yes, Duplicate
                  </button>
                </div>
              </div>
            </div>
          )}
      {showDeletePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 w-96">
            <h2 className="text-xl font-semibold mb-4">Delete SMS Campaign</h2>
            <p className="mb-6">
             You are about to delete this campaign. Please confirm before proceeding.
            </p>
            <div className="flex justify-between">
              <button
                onClick={handleCloseDeletePopup}
                className=" px-4 py-2 rounded-lg flex items-center"
              >
                <FiArrowLeft className="w-6 h-6 mr-2" />
                No, go back
              </button>

              <button
                onClick={handleDelete}
                className="bg-red-600 text-white px-4 py-2 rounded-lg"
              >
                Yes, Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SMSCampaigns;

const TruncatedText = ({ text, maxLength = 100 }) => {
  const truncatedText =
    text.length > maxLength ? text.substr(0, maxLength) + "..." : text;

  return (
    <div className="group relative">
      <div className="max-w-xs overflow-hidden">{truncatedText}</div>
      {text.length > maxLength && (
        <div className="absolute z-10 invisible group-hover:visible bg-gray-600 text-white p-2 rounded shadow-lg mt-2 text-sm w-72">
          {text}
        </div>
      )}
    </div>
  );
};
