import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import Dashboard from "../components/Dashboard";
import Login from "../components/Login";

const Routes = () => {
  const protectedRoutes = [
    {
      path: "/",
      element: <ProtectedRoute />,
      children: [
        {
          path: "/",
          element: <Dashboard />,
        },
      ],
    },
  ];

  const unProtectedRoutes = [
    {
      path: "/login",
      element: <Login />,
    },
  ];

  const router = createBrowserRouter([
    ...protectedRoutes,
    ...unProtectedRoutes,
  ]);

  return <RouterProvider router={router} />;
};

export default Routes;
