import React from "react";
import { ToastContainer } from "react-toastify";
import Router from "./routes";
import AuthProvider from "./providers/auth-providers";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router />
      </AuthProvider>
      <ToastContainer />
    </div>
  );
}

export default App;
