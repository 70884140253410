import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { FiArrowLeft, FiTrash } from "react-icons/fi";
import SMSCampaigns from "./SMSCampaigns";
import { AiOutlineUpload } from "react-icons/ai";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  cleanRecipients,
  copyCampaign,
  createSMSCampaigns,
  fetchCampaignRecipients,
  sendCampaign,
  updateCampaignStatusDuplicate,
  uploadCSV,
  fetchRecipients as fetchRecipientsAPI,
} from "../api";

const NewCampaigns = ({ campaignData }) => {
  const [name, setName] = useState(campaignData ? campaignData.name : "");
  const [senders_Name, setSenders_Name] = useState(
    campaignData ? campaignData.senders_name : ""
  );
  const [message, setMessage] = useState(
    campaignData ? campaignData.message : ""
  );
  // eslint-disable-next-line no-unused-vars
  const [csvFile, setCsvFile] = useState(null);
  const [isCampaignSaved, setIsCampaignSaved] = useState(!!campaignData);
  const [isCsvUploaded, setIsCsvUploaded] = useState(false);
  const fileInputRef = React.useRef(null);
  const [recipients, setRecipients] = useState([]);
  const [precentage, setPercentage] = useState([]);
  const [showHome2, setShowHome2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmDuplicate, setShowConfirmDuplicate] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showSendResult, setShowSendResult] = useState(false);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [totalDelivered, setTotalDelivered] = useState(0);
  const [totalUndelivered, setTotalUndelivered] = useState(0);
  const [totalPending, setTotalPending] = useState(0);
  const [newCampaignId, setNewCampaignId] = useState(null);
  const [showConfirmSend, setShowConfirmSend] = useState(false);
  const [setCampaignStats] = useState({
    total_recipients: 0,
    undelivered: 0,
    delivered: 0,
    pending: 0,
    percentage: 0,
  });

  const handleSave = async () => {
    try {
      const response = await createSMSCampaigns({
        name,
        message,
        senders_name: senders_Name,
        status: "draft",
      });
      setNewCampaignId(response.data.campaign_id);
      setIsCampaignSaved(true);
      toast.success("Campaign Saved Successfully");
    } catch (error) {
      toast.error("Failed to save the campaign");
    }
  };

  const handleUploadCSV = (event) => {
    const campaignId = newCampaignId || (campaignData && campaignData.id);
    const file = event.target.files[0];
    setCsvFile(file);
    if (!file || !campaignId) return;
    const formData = new FormData();
    formData.append("file", file);
    uploadCSV(campaignId, formData)
      .then((response) => {
        if (response.status === 201) {
          fetchRecipients();
          setIsCsvUploaded(true);
          toast.success("CSV upload successfully.");
        }
      })
      .catch((error) => toast.error("Error uploading CSV", error));
  };

  const handleDuplicate = async () => {
    const campaignId = newCampaignId || (campaignData && campaignData.id);
    try {
      const response = await copyCampaign(campaignId);
      if (response.status === 201) {
        const newCampaignId = response.data.campaign_id;
        await updateCampaignStatusDuplicate(newCampaignId, "DRAFT");
      }
      toast.success("Campaign Duplicated successfully!");
      setShowHome2(true);
    } catch (error) {
      console.error("Error Duplicating campaign:", error);
      toast.error("Failed to Duplicate the campaign.");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchCampaignRecipients(campaignData.id, 1, 10);
        const {
          total_recipients,
          undelivered,
          delivered,
          pending,
          percentage,
        } = response.data;
        setCampaignStats({
          total_recipients,
          undelivered,
          delivered,
          pending,
          percentage,
        });
      } catch (error) {
        console.error("Error fetching campaign data:", error);
      }
    };
    fetchData();
    if (
      campaignData &&
      (campaignData.status === "SENT" || campaignData.status === null)
    ) {
      setShowSendResult(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignData]);

  useEffect(() => {
    if (isCampaignSaved) {
      fetchRecipients(pageNumber, pageSize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, isCampaignSaved]);

  const pageSize = 10;

  const fetchRecipients = async (pageNumber) => {
    try {
      const campaignId = newCampaignId || (campaignData && campaignData.id);
      if (!campaignId) {
        console.error("No campaign available");
        return;
      }
      const response = await fetchRecipientsAPI(
        campaignId,
        pageNumber,
        pageSize
      );
      const {
        data,
        page_number,
        total_pages,
        total_recipients,
        delivered,
        undelivered,
        pending,
        percentage,
      } = response.data;
      setRecipients(data);
      setPercentage(percentage);
      setPageNumber(page_number);
      setTotalPages(total_pages);
      setTotalRecipients(total_recipients);
      setTotalDelivered(delivered);
      setTotalUndelivered(undelivered);
      setTotalPending(pending);
    } catch (error) {
      toast.error("Error fetching recipients:", error);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < totalPages) {
      fetchRecipients(pageNumber + 1);
    }
  };
  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      fetchRecipients(pageNumber - 1);
    }
  };
  const handleCleanRecipients = async () => {
    const campaignId = newCampaignId || (campaignData && campaignData.id);
    try {
      await cleanRecipients(campaignId);
      fetchRecipients();
      setShowModal(false);
      toast.success("Recipients cleaned successfully.");
    } catch (error) {
      toast.error("Error cleaning recipients:", error);
    }
  };
  if (showHome2) {
    return <SMSCampaigns />;
  }

  const handleSend = async () => {
    setShowConfirmSend(true);
  };
  const handleConfirmSend = async () => {
    // eslint-disable-next-line react/prop-types
    const campaignId = newCampaignId || (campaignData && campaignData.id);
    try {
      await sendCampaign(campaignId, {
        name,
        message,
        senders_name: senders_Name,
        status: "SENT",
      });
      toast.success("Campaign SMS Send Successfully");
      setIsCampaignSaved(true);
      setShowSendResult(true);
      setShowConfirmSend(false);
      setShowHome2(true);
    } catch (error) {
      console.error("Error sending campaign", error);
    }
  };

  const handleCancelSend = () => {
    setShowConfirmSend(false);
  };

  return (
    <div className="flex flex-col h-full p-5 bg-gray-100">
      <h1 className="text-[#242424] text-2xl font-semibold mb-4 flex items-center cursor-pointer">
        <FiArrowLeft
          className="w-6 h-6 mr-2"
          onClick={() => setShowHome2(true)}
        />
        {showSendResult ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {campaignData.name}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                backgroundColor: "#ECECEC",
                borderRadius: "20px",
                padding: "1px 5px",
              }}
            >
              <span
                style={{
                  height: "10px",
                  width: "10px",
                  borderRadius: "50%",
                  backgroundColor: totalPending === 0 ? "green" : "orange",
                  display: "inline-block",
                  marginRight: "8px",
                }}
              />
              <span style={{ fontSize: "15px" }}>
                {totalPending === 0
                  ? "Sent"
                  : totalPending > 0
                  ? "In Progress"
                  : "Pending"}
              </span>
            </div>
          </div>
        ) : campaignData ? (
          "Edit Campaign"
        ) : (
          "New Campaign"
        )}
      </h1>

      {showSendResult && (
        <div className="mb-6 mt-5 bg-white rounded-xl">
          <h2 className="text-xl font-semibold mb-2 ml-5 mt-4">Result</h2>
          <div className="flex flex-wrap justify-between gap-2 m-5">
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="flex-1 p-5 text-center rounded-xl w-full sm:w-1/2 lg:w-1/5"
            >
              <p className="text-4xl">{totalRecipients}</p>
              <p>Total Recipients</p>
            </div>
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="flex-1 bg-black p-5 text-center rounded-xl w-full sm:w-1/2 lg:w-1/5"
            >
              <p className="text-4xl" style={{ color: "#f1c40f" }}>
                {totalPending}
              </p>
              <p>Pending</p>
            </div>
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="flex-1 bg-black p-5 text-center rounded-xl w-full sm:w-1/2 lg:w-1/5"
            >
              <p className="text-4xl" style={{ color: "#07bc0c" }}>
                <span>{totalDelivered}</span>
                <span
                  style={{
                    fontSize: "16px",
                    marginLeft: "8px",
                    color: precentage > 50 ? "#07bc0c" : "blue",
                  }}
                >
                  {precentage}%
                </span>
              </p>

              <p>Delivered</p>
            </div>
            <div
              style={{ backgroundColor: "#F5F5F5" }}
              className="flex-1 bg-black p-5 text-center rounded-xl w-full sm:w-1/2 lg:w-1/5"
            >
              <p className="text-4xl" style={{ color: "#e34c3b" }}>
                {totalUndelivered}
              </p>
              <p>Undelivered</p>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4 h-full">
        <div className="w-full lg:w-1/2 bg-white p-5 rounded-xl shadow-lg">
          <div className="flex flex-col lg:flex-row justify-between mb-4">
            <div className="flex flex-col w-full lg:w-[48%] mb-4 lg:mb-0">
              <label className="text-md font-bold mb-2">Campaign Name</label>
              <input
                type="text"
                className="rounded-lg p-2 bg-[#F6F6F6] border-none"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Campaign Name"
              />
            </div>
            <div className="flex flex-col w-full lg:w-[48%]">
              <label className="text-md font-bold mb-2">Sender's Name</label>
              <input
                type="text"
                className="rounded-lg p-2 bg-[#F6F6F6] border-none"
                value={senders_Name}
                onChange={(e) => setSenders_Name(e.target.value)}
                placeholder="Enter Sender's Name"
              />
            </div>
          </div>

          <div className="flex flex-col mb-4">
            <label className="text-md font-bold mb-2">Text Message</label>
            <textarea
              className="rounded-lg p-2 bg-[#F6F6F6] border-none h-24"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Enter the campaign message"
            />
            <div className="text-sm text-gray-500 mt-2 text-right">
              Characters: {message.length}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              onClick={() => {
                if (
                  (campaignData && campaignData.status === "SENT") ||
                  showSendResult
                ) {
                  setShowConfirmDuplicate(true);
                } else if (campaignData || newCampaignId) {
                  handleSend();
                } else {
                  handleSave();
                }
              }}
              className={`px-6 py-2 text-white rounded-lg ${
                (campaignData && campaignData.status === "SENT") ||
                showSendResult
                  ? "bg-blue-600 cursor-pointer"
                  : "bg-blue-600"
              }`}
            >
              {(campaignData && campaignData.status === "SENT") ||
              showSendResult
                ? "Duplicate"
                : isCsvUploaded || campaignData
                ? "Send"
                : "Save"}
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 bg-white p-2 rounded-xl shadow-lg relative">
          <h3 className="text-lg m-5 font-semibold mt-1 mb-2">Recipients</h3>
          <input
            type="file"
            accept=".csv"
            onChange={handleUploadCSV}
            ref={fileInputRef}
            style={{ display: "none" }}
            disabled={!isCampaignSaved}
          />
          <button
            onClick={() => fileInputRef.current.click()}
            className={`absolute top-2 right-4 text-blue-500 px-4 py-2 rounded-lg flex items-center ${
              !isCampaignSaved || showSendResult
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={!isCampaignSaved || showSendResult}
          >
            <AiOutlineUpload className="mr-2" />
            Upload CSV
          </button>
          <div className="flex justify-center mt-4">
            <table
              style={{ width: "100%" }}
              className=" rounded-lg overflow-hidden  text-sm mx-auto"
            >
              <thead className="bg-gray-100">
                <tr>
                  <th
                    className="py-3 px-5 text-left"
                    style={{
                      backgroundColor: "#F5F5F5",
                      borderLeft: "1px solid #EBEBEB", 
                    }}
                  >
                    Phone
                  </th>
                  <th
                    className="py-3 px-5 text-left"
                    style={{
                      backgroundColor: "#F5F5F5",
                      borderRight: "1px solid #EBEBEB",
                    }}
                  >
                    Name
                  </th>
                </tr>
              </thead>

              <tbody>
                {recipients.length > 0 ? (
                  recipients.map((recipient, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0 ? "bg-white" : "bg-gray-50"
                      }`}
                    >
                      <td className="py-3 px-5 text-left">
                        {recipient.mobile_number}
                      </td>
                      <td className="py-3 px-5 text-left">{recipient.name}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="py-3 px-5 text-center">
                      No recipients found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="overflow-x-auto">
            <div className="flex justify-between items-center pl-8">
              {totalRecipients > 0 && (
                <p className="text-lg text-center m-3 text-black font-bold">
                  You have uploaded {totalRecipients} recipients.
                </p>
              )}
            </div>
          </div>

          <div
            style={{ height: "40px" }}
            className="flex justify-between rounded-xl items-center bg-gray-100"
          >
            <div className="flex  items-center space-x-4">
              <button
                onClick={handlePreviousPage}
                className={`bg-gray-200 text-gray-700 rounded-lg ${
                  pageNumber === 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
                disabled={pageNumber === 1}
              >
                <AiOutlineLeft size={20} />
              </button>
              <div className="text-gray-700">
                Page {pageNumber} of {totalPages}
              </div>
              <button
                onClick={handleNextPage}
                className={`bg-gray-200 text-gray-700 rounded-lg ${
                  pageNumber === totalPages
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={pageNumber === totalPages}
              >
                <AiOutlineRight size={20} />
              </button>
            </div>
            <button
              onClick={() => setShowModal(true)}
              className="flex items-center text-red-700 mr-2 hover:text-black"
            >
              <FiTrash className="mr-2" size={20} />
              Clean all recipients
            </button>
          </div>
          {showConfirmSend && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">Confirm Send</h2>
                <p className="my-4">
                  Are you sure you want to send the campaign to{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {totalRecipients} recipients
                  </span>
                  ?
                </p>
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={handleCancelSend}
                    className=" px-4 py-2 rounded-lg flex items-center"
                  >
                    <FiArrowLeft className="w-6 h-6 mr-2" />
                    No, go Back
                  </button>

                  <button
                    onClick={handleConfirmSend}
                    className="bg-blue-600 text-white px-4 py-2 rounded-lg"
                  >
                    Yes, Send
                  </button>
                </div>
              </div>
            </div>
          )}

          {showModal && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">Clean Table</h2>
                <p className="my-4">
                  Are you about to clean the table, Please confirm before proceeding.
                </p>
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() => setShowModal(false)}
                    className=" px-4 py-2 rounded-lg flex items-center"
                  >
                    <FiArrowLeft className="w-6 h-6 mr-2" />
                    No, go back
                  </button>
                  <button
                    onClick={handleCleanRecipients}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg"
                  >
                    Yes, Clean
                  </button>
                </div>
              </div>
            </div>
          )}
          {showConfirmDuplicate && (
            <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <h2 className="text-xl font-semibold mb-4">
                  Duplicate Campaign
                </h2>
                <p className="my-4">
                  Are you about to Duplicate the campaign? Please confirm before proceeding.
                </p>
                <div className="flex justify-between items-center mb-4">
                  <button
                    onClick={() => setShowConfirmDuplicate(false)}
                    className=" px-4 py-2 rounded-lg flex items-center"
                  >
                    <FiArrowLeft className="w-6 h-6 mr-2" />
                    No, go back
                  </button>
                  <button
                    onClick={handleDuplicate}
                    className="bg-red-600 text-white px-4 py-2 rounded-lg"
                  >
                    Yes, Duplicate
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewCampaigns;
